@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('assets/css/global.scss');

@import url('react-toastify/dist/ReactToastify.css');

@media (hover: none) {
  .touch\:hidden {
    display: none;
  }
}

@layer base {
  *,
  ::after,
  ::before {
    border: 0 solid #e5e7eb;
  }
}
