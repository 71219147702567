@font-face {
  font-family: 'Nexa';
  src: url('../fonts/nexa_english/nexa_heavy.ttf');
  src: url('../fonts/nexa_english/nexa_heavy.ttf') format('tff');
  font-weight: 700;
}

@font-face {
  font-family: 'Nexa';
  src: url('../fonts/nexa_english/nexa_extra_light.ttf');
  src: url('../fonts/nexa_english/nexa_extra_light.ttf') format('tff');
  font-weight: 100;
}

body {
  font-family: 'Nexa', sans-serif;
  margin: 0;
  padding: 0;
}
button[disabled] {
  cursor: not-allowed;
  &.disable {
    @apply tw-bg-[#BEBEBE] tw-cursor-not-allowed;
    foo: bar;
  }
}

/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #2c3255;
}

input:focus-visible {
  outline: none;
}

// foo:bar nothings to do with CSS its a dirty and quick solution to avoid production build runtime error
.error {
  @apply tw-text-error tw-text-[13px] md:tw-text-[11px] tw-font-[500];
  foo: bar;
}

.success {
  @apply tw-text-electricGreen tw-text-[13px] md:tw-text-[11px] tw-font-[500];
  foo: bar;
}

.font-heading {
  font-family: Poppins, sans-serif;
}

.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Set the color of the thumb (the draggable part) */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #888; /* Set the color of the thumb on hover */
}

/* ----------------------------------------------------------------- */
/*                          INPUT RADIO                              */
/* ----------------------------------------------------------------- */

[type='checkbox']:checked:focus,
[type='checkbox']:checked:hover,
[type='radio']:checked:focus,
[type='radio']:checked:hover {
  background-color: currentColor;
  border-color: transparent;
}
.focus\:ring-electricGreen:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 215 97 / var(--tw-ring-opacity));
}
.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.focus\:ring-0:focus,
.focus\:ring-2:focus {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
[type='radio']:checked {
  background-image: url('../images/radio.svg');
}
[type='checkbox']:focus,
[type='radio']:focus {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px;
}
[type='radio'] {
  border-radius: 100%;
}

[type='checkbox'] {
  accent-color: #2c3255;
}

[type='radio'] {
  --tw-shadow: 0 0 #0000;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-origin: border-box;
  border-color: #6b7280;
  border-width: 1px;
  color: #2563eb;
  display: inline-block;
  flex-shrink: 0;
  height: 1rem;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 1rem;
}
[type='radio']:checked {
  background-color: currentColor;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: transparent;
}
/* ----------------------------------------------------------------- */
/*                          INPUT FIELDS                             */
/* ----------------------------------------------------------------- */
.signup-input {
  @apply tw-grow tw-flex tw-flex-col tw-gap-[7px];
  foo: bar;
  .label {
    @apply tw-text-[#304861] tw-text-[15px] md:tw-text-[13px] tw-font-[500];
    foo: bar;
  }
  .input-wrap {
    @apply tw-bg-[#F2F5F5]  tw-rounded-[5px] tw-min-h-[55px] md:tw-min-h-[40px] tw-px-[11px] tw-flex tw-items-center;
    foo: bar;
  }
  input {
    @apply tw-w-full tw-h-full focus:tw-outline-none focus:tw-ring-transparent tw-bg-transparent tw-outline-none tw-border-none tw-p-0 tw-text-[16px] tw-placeholder-gray-300 md:tw-text-[14px]  tw-text-black;
    foo: bar;
  }
}

/* ----------------------------------------------------------------- */
/*                             BUTTON                                */
/* ----------------------------------------------------------------- */
button.btn {
  @apply tw-w-full tw-cursor-pointer tw-bg-electricGreen tw-text-white tw-text-[15px] tw-font-[500] tw-rounded-[5px] tw-py-[12px] md:tw-py-[14px] tw-text-center;
  foo: bar;
  &.disable {
    @apply tw-bg-[#BEBEBE] tw-cursor-not-allowed;
    foo: bar;
  }
}

/* ----------------------------------------------------------------- */
/*                             VERIFICATION                          */
/* ----------------------------------------------------------------- */

:where(.vi__wrapper) {
  position: relative;
  width: min-content;
}
.vi {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  position: absolute;
  color: transparent;
  background: transparent;
  caret-color: transparent;
  outline: none;
  border: 0 none transparent;
}
.vi::-ms-reveal,
.vi::-ms-clear {
  display: none;
}
.vi::selection {
  background: transparent;
}
:where(.vi__container) {
  display: flex;
  gap: 8px;
  height: 50px;
  width: 300px;
}
:where(.vi__character) {
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  font-size: 36px;
  line-height: 50px;
  color: black;
  background-color: white;
  border: 1px solid black;
  cursor: default;
  user-select: none;
  box-sizing: border-box;
}
:where(.vi__character--inactive) {
  color: dimgray;
  background-color: lightgray;
}
:where(.vi__character--selected) {
  outline: 2px solid cornflowerblue;
  color: cornflowerblue;
}

.shadow-regular {
  --tw-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05),
    4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0px -4px 4px var(--tw-shadow-color),
    0px 4px 4px var(--tw-shadow-color), 4px 0px 4px var(--tw-shadow-color),
    -4px 0px 4px var(--tw-shadow-color);
}

.shadow-md,
.shadow-regular {
  /* box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow); */
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.regular {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.toast-message {
  // background: #1b3c69;
  --toastify-color-progress-light: linear-gradient(to right, #3192df, #1b3c69);
  color: #fff;
}
select {
  font-family: 'Segoe UI Emoji', 'Apple Color Emoji', 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif !important;
}
